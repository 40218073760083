.communities-members {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.member-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 72px;
  border-radius: 10px;
  order: 0;
  flex-grow: 0;
  padding: 8px;
  color: #000000;
  background-color: #f0f0f0;
  margin: 0 4px 4px 4px;
}

.member-item.darkModeContainer {
  background-color: #1c1c28;
}
.member-item.darkModeContainer p {
  color: #f0f0f0;
}

.member-item.darkModeContainer {
  background-color: #1c1c28;
}

.member-item:hover {
  cursor: pointer;
}

.moderator-label {
  color: #757d87;
  margin-bottom: 4px;
}
.member-item-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.member-item-top p {
  margin-top: 4px;
}

.member-item-top p:hover {
  color: #0b5dd7;
}

.member-item-top.darkModeContainer p:hover {
  color: #387adb;
}

@media screen and (min-width: 481px) {
  .member-item {
    width: 164px;
  }
}

@media screen and (max-width: 480px) {
  .member-item {
    width: 40%;
  }
}
