.trade-position thead th {
  padding: 12px;
  text-align: center;
  white-space: nowrap;
}

.trade-position thead th:first-child{
  text-align: left;
}

.trade-position tbody td {
  padding: 12px;
  text-align: center;
}

.trade-position.darkModeContainer {
  background: #272835;
  color: #F0F0F0;
}

.trade-position-container {
  overflow: auto;
  /* -webkit-overflow-scrolling: touch;  */
  /* overscroll-behavior: contain; */
  /* -webkit-transform: translateZ(0); */ 
  transform: translateZ(0); /* place scrollbar on top of sticky cols/rows*/
}

.trade-position-container.darkModeContainer > table > thead > tr > th {
  color: #F0F0F0;
}

/* .trade-position-container::-webkit-scrollbar {
  background: transparent;
}

.trade-position-container:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.trade-position-container::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.position-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.trade-position {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  /* padding: 24px 12px; */
  background: #FFFFFF;
  border-radius: 20px;
  max-height: 320px;
}

.trade-position > h6 {
  margin-bottom: 12px;
}


@media screen and (min-width: 481px){
  .trade-position table {
    width: 100%;
    border-collapse: collapse;
  }

  .trade-position thead th {
    min-width: calc(100dvw/15);
  }
}

@media screen and (max-width: 1600px){
  /* .trade-position {
    margin-left: 24px;
  } */

}

@media screen and (max-width: 480px){
  .trade-position thead th {
    min-width: calc(100dvw/4.6);
  }
}