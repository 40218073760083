.login {
  background-color: #ffffff;
}

.login > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup {
  margin-top: -5px;
  margin-bottom: 12px;
  width: 151px;
  height: 12px;
  left: 168px;
  top: 282px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #000000;
}

.login > form > p > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #007aff;
}

.login > form > p > span:hover {
  cursor: pointer;
}

.login-button:hover {
  cursor: pointer;
  background-color: #07306d;
}

.login-button:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.forgot-password {
  width: 100%;
  margin: 3px 0px 8px 9.1px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.forgot-password-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #007aff;
  text-decoration: none;
}

.forgot-password-link:hover {
  cursor: pointer;
  /* text-decoration: underline;  */
}

@media screen and (min-width: 481px) {
  .login {
    position: absolute;
    padding: 32px;
    /* width: 487px;
    height: 423px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    margin-top: 136px;
  }

  .login > h4 {
    margin-top: 12px;
    margin-bottom: 32px;

    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #000000;
  }

  .login-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #f7f9fc;

    width: 100%;
    height: 33px;

    margin-bottom: 12px;

    background: #0b5dd7;
    border-radius: 8px;

    border: none;
  }
}

@media screen and (max-width: 480px) {
  .login {
    position: absolute;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 87px;
  }

  .login > h4 {
    margin-top: 0px;
    margin-bottom: 24px;
    width: 100%;
    font-family: "Roboto Condensed";
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #000000;
  }

  .login-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #f7f9fc;

    /* width: 260px; */
    width: 100%;
    height: 33px;
    margin-bottom: 12px;

    background: #0b5dd7;
    border-radius: 8px;

    border: none;
  }
}
