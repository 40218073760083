@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
}

html {
  overflow: hidden;
  position: fixed;
  /* overflow: auto; */
  height: 100dvh;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  /* overflow: auto; */
  overflow: hidden;
  position: fixed;
}

.App {
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
}

h1 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

/* colors */
:root {
  --mustard: #d7840b;
  --mustard-dark: #d98a17;
}

/* Dashboard */
.dashboard {
  position: relative;
  background-color: #f0f0f0;
  width: 100%;
}

.dashboard.darkModeContainer {
  background-color: #1c1c28;
}

.dashboard-content-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100dvw;
}

/* GLOBAL FADE */
.faded-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3;
}

h6 {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

h5 {
  margin: 0px 0px 8px 0px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #d7840b;
}

table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

th {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  padding-left: 4px;
  color: #252930;
  box-shadow: inset 0px -2px 0px 0px #ced5de;
}

tr {
  min-height: 30px;
}

td {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  padding: 10px;
  box-shadow: inset 0px -1px 0px 0px #ced5de;
}

@media screen and (min-width: 481px) {
  .dashboard {
    height: calc(100dvh - 76px);
  }

  .dashboard-content-container {
    margin-left: 78px;
  }
}

@media screen and (max-width: 480px) {
  .dashboard {
    display: flex;
    flex-direction: column-reverse;
    height: calc(100dvh - 63px);
  }

  .dashboard-content-container {
    margin: 0px;
    justify-content: center;
    height: calc(100% - 63px);
  }
}
