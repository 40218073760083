  .report{
    display: flex;
    flex-direction: column;
    height: calc(100% - 48px);
    padding: 24px;
    border-radius: 20px;
    background: #FFFFFF;
    overflow: auto;

  }

  .report.darkModeContainer {
    background: #272835;
  }

  .report-top{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
  }

  .h4-watchlist {
    margin: 0;
  
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  
    color: #000000;
  }

  .h4-watchlist.darkModeContainer {
    color: #F0F0F0;
  }

  .report-download{
    color: #000000;
    background-color:transparent;
    border: none;
    height: 28px;
  }

  .report-download.darkModeContainer {
    color: #F0F0F0
  }
  
  .report-download:hover{
    cursor: pointer;
  }
  .report-download:disabled{
    cursor: default;
  }

  .analytics-tab-header {
    height: 48px;
  
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px 8px 16px;
    margin: 0;

    background: transparent;
    border: none;  

    color: #000000;
  }

  .analytics-tab-header.darkModeContainer {
    color: #F0F0F0;
  }

  .analytics-tab-header-container{
    margin-top: 0px;
    display: flex;
    flex-direction: row;
  }
  
  .analytics-tab-header.active {
    box-shadow: inset 0 -4px 0 0 #0B5DD7, 0 0 0 1px #0B5DD7;
  }
  
  .analytics-tab-header:hover{
    cursor: pointer;
  }
  
  .analytics-stock-header{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-right: 20px;
    margin-bottom: 4px;
    padding: 0;
    
    color: #ACB4BE;
    background: transparent;
    border: none;
  }
  
  .analytics-stock-header:hover{
    cursor: pointer;
  }

  .analytics-stock-header-container{
    margin: 14px 0px;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
  }
  
  .analytics-stock-header-container::-webkit-scrollbar {
    display: none;
  }

  .report-header {
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
  
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
  }

  .report-header.darkModeContainer{
    color: #F0F0F0;
  }
  
  .report-header:hover{
    cursor: pointer;
  }

  .arrow-container {
    display: flex;
    flex-direction: column;
  }

  .report-table-row > td {
    border-bottom: 1px solid #E0E2EA;
  
    color: #252930;
  }

  .table-container-hist {
    overflow: auto;
    transform: translateZ(0); /* place scrollbar on top of sticky cols/rows*/
    height: 100%;
    width: 100%;
    }

  /* .table-container-hist::-webkit-scrollbar {
    background: transparent;
  }
  
  .table-container-hist:hover::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
  
  .table-container-hist::-webkit-scrollbar-corner {
    background-color: transparent;
  } */

  .table-container-hist>table>tbody>tr:nth-of-type(odd) {
    background-color: #f0f0f0;
  }

  .table-container-hist.darkModeContainer>table>tbody>tr:nth-of-type(odd) {
    background-color: #1C1C28;
  }
  
  .table-container-hist>table>tbody>tr:hover {
    background-color: #ffa;
  }

  .table-container-hist.darkModeContainer>table>tbody>tr:hover {
    background-color: #D98A17;
    border-bottom: 1px solid #1C1C28; /* Add border style and width */
    border-top: 1px solid #1C1C28; /* Add border style and width */
  }

  .table-container-hist.darkModeContainer>table>tbody>tr:hover td,
  .table-container-hist.darkModeContainer>table>tbody>tr:hover th {
    color: #1C1C28;
  }


  .analytics-tab-header.darkModeContainer {
    color: #F0F0F0;
  }

  .report-table-row.darkModeContainer > td {
    color: #F0F0F0;
  }

@media screen and (min-width: 481px){
  .report{
    padding: 24px;
  }

  .analytics-tab-header {
    width: 202px;   
  }

  .report-table-row td {
    min-width: calc(100dvw/15);
  }

}

@media screen and (max-width: 480px){
  .report{
    padding: 24px 12px;
  }

  .analytics-tab-header {
    width: 164px;   
  }

  .report-table-row td {
    min-width: calc(100dvw/6.3);
  }
}