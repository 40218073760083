.analytics-stock-header{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 12px;
  padding: 0;

  color: #ACB4BE;
  background: transparent;
  border: none;
}
  
.analytics-stock-header:hover{
  cursor: pointer;
}

.table-container-hist {
  /* max-width: 500px; */
}

/* .table-container-hist::-webkit-scrollbar {
  background: transparent;
}

.table-container-hist:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.table-container-hist::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.table-container-hist>table>tbody>tr:nth-of-type(odd) {
  background-color: #f0f0f0;
}

.table-container-hist.darkModeContainer>table>tbody>tr:nth-of-type(odd) {
  background-color: #1C1C28;
}

.table-container-hist>table>tbody>tr:hover {
  background-color: #ffa;
}

.table-container-hist.darkModeContainer>table>tbody>tr:hover {
  background-color: #D98A17;
  border-bottom: 1px solid #1C1C28;
  border-top: 1px solid #1C1C28;
}

.table-container-hist.darkModeContainer>table>tbody>tr:hover td,
.table-container-hist.darkModeContainer>table>tbody>tr:hover th {
  color: #1C1C28;
}

.report-header {
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
}

.report-header.darkModeContainer{
  color: #F0F0F0;
}

.report-header:hover{
  cursor: pointer;
}

.report-table-row > td {
  width: 104px;
  /* border-bottom: 1px solid #E0E2EA; */
  color: #252930;
}

.report-table-row.darkModeContainer > td {
  color: #F0F0F0;
}

.table-container {
  display: flex;
}

.table-container.darkModeContainer {
  color: #F0F0F0;
}

.table-container-summary.darkModeContainer > table > thead > tr > th{
  color: #F0F0F0;
}

.table-container-summary.darkModeContainer > table > tbody > tr > th{
  color: #F0F0F0;
}

.table-container-summary table tbody tr th {
  box-shadow: inset 0px -1px 0px 0px #CED5DE;
}

/* .table-container-summary::-webkit-scrollbar {
  background: transparent;
}

.table-container-summary:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.table-container-summary::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.analytics-stock-header-container{
  margin: 14px 0px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

.analytics-stock-header-container::-webkit-scrollbar {
  display: none;
}

.analytics-portfolio {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.table-container-hist {
  width: 100%;
}

@media screen and (min-width: 481px){
.table-container{
  flex-direction: row;
  align-items: flex-start;
}

.table-container-summary {
  margin-left: 22px;
  min-width: 204px;
}
}

@media screen and (max-width: 480px){
.table-container{
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.table-container-summary {
  margin-right: 12px;
  margin-bottom: 22px;
  order: -1;
}
}