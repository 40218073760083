.position-container {
display: flex;
flex-direction: column;
background: #FFFFFF;
border-radius: 20px;
}

.position-container.darkModeContainer {
background: #272835;
color: #F0F0F0;
}

.position-header {
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-bottom: 12px;
}

.position-header span {
display: flex;
flex-direction: row;
align-items: center;
}

.position-header span:hover {
cursor: pointer;
}

@media screen and (min-width: 481px){
    .position-container{
        margin-top: 24px;
        padding:24px
    }
}

@media screen and (max-width: 480px){
    .position-container{
        margin-top: 12px;
        padding: 24px 12px;
    }
}