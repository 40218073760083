.news {
  min-height: 278px;
}

.news-content > img {
  border-radius: 10px;
  margin: 0px;
}

/* .news::-webkit-scrollbar {
  background: transparent;
}

.news:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.news::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.news-title {
  color: #000000;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit the number of lines */
  -webkit-box-orient: vertical;}

.news-title.darkModeContainer {
  color: #F0F0F0;
}

.news-preview {
  color: #000000;
}
.news-preview.darkModeContainer {
  color: #F0F0F0;
}

.news-text {
  /* height: 70px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.news-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  margin-bottom: 4px;
}

.hours {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;

  color: #757D87;
}

.news-content:first-child {
  padding-top: 0px;
}

.news-content:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}
.news-content {
  padding: 12px 0px;
  border-bottom: 1px solid #757D87;
  width: 100%;

}

@media screen and (min-width: 481px){
  /* .news {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

  .news-content {
    display: flex;
    flex-direction: row;
    /* align-items: center;
    justify-content: space-between; */
  }

  .news-text {
    margin-left: 12px;
  }

  .news-title {
    font-size: 16px;
  }

  .hours {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px){
  .news-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .news-text {
    margin-right: 24px;
  }

  .news-title {
    font-size: 12px;
  }

  .hours {
    font-size: 8px;
  }
}