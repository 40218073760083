.watchlist {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100dvw;
}

.watchlist-left {
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.watchlist-right {
  padding: 24px;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
}

.tab-heading {
  width: 100%;
  height: 56px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  display: flex;
  align-items: flex-end;
  color: #757d87;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 24px 0px;
}

.tab-heading.darkModeContainer {
  color: #c6cfda;
}

.watchlist-search {
  display: flex;
  flex-direction: row;
  height: 72px;
  margin-left: 0px;
  width: 100%;
}

.watchlist-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px 0px 12px 0px;
}

.watchlist-button-container > button {
  margin: 0px 24px 0px 24px;
}

.watchlist-row-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  border-radius: 20px;
  flex-shrink: 0; /* Prevent the element from shrinking */
  margin-bottom: 10px; /* Add margin between elements */
}

.watchlist-br-content-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;

  padding: 8px 16px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  background-color: transparent;
  border: 0px;
  border-bottom: 4px solid #ced5de;
}

.watchlist-row-element:last-child {
  margin-bottom: 0px;
}

.watchlist-br-content-container {
  padding: 24px 12px;
}

.watchlist-search > img {
  margin-left: 12px;
}

.watchlist-search > img:hover {
  cursor: pointer;
}

.watchlist-button {
  margin: 0px;
  padding: 0px;
  background: transparent;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #acb4be;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.watchlist-button:hover {
  cursor: pointer;
}

.watchlist-button-selected {
  border: none;
  background: transparent;
  margin: 0px;
  padding: 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #0b5dd7;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.watchlist-button-selected.darkModeContainer {
  color: #387adb;
}

.list-container {
  width: 100%;
  /* min-height: 144px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 810px;
  border: 1px solid #757d87;
  border-radius: 20px;
  overflow: auto;
  height: 100%;
}

.list-container > p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 265px;
  height: 34px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #acb4be;
}

.watchlist-alert {
  /* margin: 12px 0px 0px 0px; */
  height: 24px;
  /* width: 100%; */
}

.watchlist-row-element:hover {
  cursor: pointer;
}

.watchlist-row-subelement-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}

.watchlist-row-subelement-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 16px;
}

.subelement-ticker {
  margin-bottom: 4px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.subelement-ticker.darkModeContainer {
  color: #f0f0f0;
}

.subelement-shortname {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #757d87;
}

.subelement-price {
  margin-bottom: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: right;

  color: #000000;
}

.subelement-price.darkModeContainer {
  color: #f0f0f0;
}

.subelement-change {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #757d87;
}

/* .watchlist-right::-webkit-scrollbar {
  background: transparent;
}

.watchlist-right:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.watchlist-right::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.watchlist-br-content-button:hover {
  cursor: pointer;
}

.watchlist-br-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  background: #ffffff;
  margin-top: 12px;
}

.watchlist-br-content-container.darkModeContainer {
  background: #272835;
}

.watchlist-br-content-button-container {
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; */
  padding: 0px;

  /* margin-left: 32px; */
  margin-bottom: 12px;
  /* margin-right: 32px; */
  /* width: 100%; */
}

.watchlist-br-content-button-container::-webkit-scrollbar {
  display: none;
}

.list-container::-webkit-scrollbar {
  display: none;
}

/* .list-container::-webkit-scrollbar {
  background: transparent;
}

.list-container:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.list-container::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.watchlist-br-content-button {
  color: #000000;
  /* width: 100px; */
}

.watchlist-br-content-button.darkModeContainer {
  color: #f0f0f0;
}

.more-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;

  font-family: "Inter";
  color: #8a8893;
  /* color: #0b5dd7; */
  /* text-decoration: underline; */
}

.more-button:hover {
  cursor: pointer;
}

.more-button.darkModeContainer {
  /* color: #387adb; */
  color: #acb4be;
}

.placeholder-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  color: #757d87;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 0px 24px 0px;
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  .watchlist {
    flex-direction: column;
    overflow-y: scroll;
  }

  .watchlist-left {
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px;
  }

  .list-container {
    height: 400px;
  }

  .watchlist-right {
    width: calc(100% - 78px);
    height: auto;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 851px) {
  .watchlist-left {
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px;
  }

  .watchlist-right {
    width: calc(100% - 408px - 78px);
    box-sizing: border-box;
    overflow-y: scroll;
    height: auto;
  }

  .watchlist-br-content-container {
    margin-top: 24px;
    padding: 24px;
  }
}

@media screen and (max-width: 480px) {
  .watchlist-left {
    width: 100%;
  }

  .watchlist-right {
    width: 100%;
    overflow-y: scroll;
    height: auto;
  }

  .watchlist-row-element {
    height: 60px;
  }
  .watchlist-br-content-container {
    margin-top: 12px;
    padding: 24px 12px;
  }
}
