.communities-fund {
    display: inline-block;
    /* overflow: auto; */
}

@media screen and (min-width: 481px){

}

@media screen and (max-width: 480px){

}