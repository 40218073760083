/* GLOBAL SIGNUP */
.signup {
  background-color: #ffffff;
}

.signup > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;
}

.signup-bottom > p {
  width: 146px;
  height: 30px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #000000;
  margin-left: 4.55px;
}

.signup-bottom > p > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #007aff;
}

.signup-bottom > p > span:hover {
  cursor: pointer;
}

.signup-button:hover {
  cursor: pointer;
  background-color: #07306d;
}

.textfield-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.login {
  margin-top: -5px;
  margin-bottom: 32px;
  height: 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #000000;
}

.resend {
  display: inline-block;
  width: 32px;
  text-align: left;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #007aff;
}

/* .resend:hover {
  cursor: pointer;
} */

.signup-button:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.reset-button-alt {
  margin-top: 4.55px;
}

.reset-button-alt:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.reset-button-alt:hover {
  cursor: pointer;
}

@media screen and (min-width: 481px) {
  .signup {
    position: absolute;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 4;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
  }

  .signup > h4 {
    margin-top: 12px;
    margin-bottom: 32px;
    /* margin-bottom: 48.5px; */
    /* width: 229px; */
    /* height: 32px; */
    font-family: "Roboto Condensed";
    font-size: 28px;
    /* line-height: 32px; */

    display: flex;
    flex-direction: column;
    align-items: center;

    /* color: #000000; */
  }

  .signup-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 437px; */
    margin-top: 15.5px;
  }

  .signup-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    color: #f7f9fc;

    width: 111px;
    height: 54px;
    /* left: 298px;
    top: 630px; */

    background: #0b5dd7;
    border-radius: 8px;
    border: none;
  }

  .reset-button-alt {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #f7f9fc;

    height: 33px;

    margin-bottom: 12px;
    /* margin-top: 12px; */

    background: #0b5dd7;
    border-radius: 8px;

    border: none;

    width: 260px;
    margin-right: 38.55px;
    margin-left: 38.55px;
  }

  .signup-bottom p {
    /* text-align: center; */
    margin-top: 0px;
    text-wrap: nowrap;
  }
}
@media screen and (max-width: 480px) {
  .signup {
    position: absolute;
    padding: 24px;
    /* width: calc(100dvw - 48px);
    height: calc(100dvh - 48px); */
    /* max-height: 510px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 4;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 87px;
    /* margin: 24px; */
    overflow-y: scroll;
    max-height: calc(100dvh - 189px);
  }

  .signup > h4 {
    margin-top: 0px;
    margin-bottom: 24px;
    /* margin-top: 32px;
    margin-bottom: 32px; */
    /* width: 229px;
    height: 32px; */
    font-family: "Roboto Condensed";
    font-size: 28px;
    /* line-height: 32px; */

    display: flex;
    flex-direction: column;
    align-items: center;

    /* color: #000000; */
  }

  .signup-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* width: 260px; */
  }

  .signup-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    color: #f7f9fc;

    width: 100px;
    height: 54px;

    background: #0b5dd7;
    border-radius: 8px;
    border: none;
  }

  .reset-button-alt {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #f7f9fc;

    height: 33px;
    margin-bottom: 12px;
    /* margin-top: 12px; */

    background: #0b5dd7;
    border-radius: 8px;

    border: none;
    width: 260px;
    margin-right: 4.55px;
    margin-left: 4.55px;
  }
}
