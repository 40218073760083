.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
}

.about.darkModeContainer {
  color: #F0F0F0;
}

.about p {
  margin: 0;
  /* text-align: justify; */
}

.about-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}

.about-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
  /* max-width: 420px; */
}
.about-info-alt {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 0%;
  /* max-width: 420px; */
}

.about-description {
  width: 100%;
  text-align: justify;
}

.about-break {
  padding-bottom: 6px;
}

.about-link {
  color: #000000
}

.about-link.darkModeContainer {
  color: #F0F0F0
}

@media screen and (min-width: 1201px){ 
  .about-header {
    font-size: 24px;
  }

  .about-bottom{
    display: flex;
    flex-direction: row;
  }

  .about-info {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    margin-left: 24px;
    white-space: nowrap; /* Prevent text wrapping */
    border-left: 1px solid #CED5DE;
    padding-left: 24px;
  }

  .about-address {
    margin-bottom: 24px;
  }

  .about-bottom {
    flex-direction: row-reverse;
  }

  .about-description {
    min-width: 400px;
  }
}

@media screen and (max-width: 1200px){
  .about-header {
    font-size: 24px;
  }

  .about-address {
    width: 50%;
  }

  .about-sector {
    width: 50%;
  }
}

@media screen and (max-width: 480px){
  .about-header {
    font-size: 18px;
  }

  .about p {
    font-size: 10px;
  }
}