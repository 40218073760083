footer {
    /* border-top: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #F7F9FC;
    height: 100px; */
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* justify-content: space-between; */
    
  }
  
  footer > div > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #076AE0;
    margin: 15px 0px 0px 0px;
  }