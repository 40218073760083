.trade-position {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  /* border-radius: 20px; */
}

/* .trade-position > h6 {
  margin-bottom: 12px;
} */

.trade-position thead th {
  padding: 12px;
  text-align: center;
  white-space: nowrap;
}

.trade-position thead th:first-child{
  text-align: left;
}

.trade-position tbody td {
  padding: 12px;
  text-align: center;
}

.trade-position.darkModeContainer {
  background: #272835;
  color: #F0F0F0;
}

.trade-position-container {
  overflow: auto;
  /* -webkit-overflow-scrolling: touch;  */
  /* overscroll-behavior: contain; */
  /* -webkit-transform: translateZ(0); */ 
  transform: translateZ(0); /* place scrollbar on top of sticky cols/rows*/
}

.trade-position-container.darkModeContainer > table > thead > tr > th {
  color: #F0F0F0;
}

.position-name span {
  white-space: nowrap;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ticker {
  font-size: 12px;
}

.shortname {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  color: #757D87;
}

/* .trade-position-container::-webkit-scrollbar {
  background: transparent;
}

.trade-position-container:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.trade-position-container::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.placeholder-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  color: #757D87;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.positions-table {
  /* table-layout: fixed; */
  width: 100%;
  border-collapse: collapse;
}

.positions-table th {
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  top: 0;
  background-color: #FFFFFF;
}

.positions-table.darkModeContainer th {
  background-color: #272835;
}

.positions-table th:first-child {
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  top: 0;
  left: 0;
  z-index: 2;
}

.positions-table th:first-child,
.positions-table td:first-child {
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  left: 0;
  background-color: #FFFFFF;
}

.positions-table th:first-child::after,
.positions-table td:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%; 
  width: 1px; 
  background-color: #CED5DE; 
}

.positions-table th:first-child {
  box-shadow: inset 0px -2px 0px 0px #CED5DE;
}

.positions-table td:first-child {
  box-shadow: inset 0px -1px 0px 0px #CED5DE;
}

.positions-table.darkModeContainer th:first-child,
.positions-table.darkModeContainer td:first-child {
  background-color: #272835;
}

.positions-table th:not(:first-child),
.positions-table td:not(:first-child) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}


@media screen and (min-width: 481px){
    .trade-position {
      /* margin-top: 24px; */
      max-height: 320px;
      /* padding: 24px; */
    }
    .trade-position thead th {
      min-width: calc(100dvw/15);
    }
}

@media screen and (max-width: 480px){
  .trade-position {
    /* margin-top: 12px; */
    max-height: 320px;
    /* padding: 24px 12px; */
  }
  
  .trade-position thead th {
    min-width: calc(100dvw/4.8);
  }
    
}