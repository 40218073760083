/* SEARCHBAR */

.searchbar > div > div > input {
    /* position: relative; */
    /* Search Input */
  
  
    /* Auto layout */
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  
    width: 201px;
    /* height: 38px; */
  
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
    outline: none;
    /* border: none; */
    background-color: transparent;
  
    /* border-bottom: 10px;
    border-top:#07306D; */
  
    /* border-bottom: 1px solid #CED5DE; */
    border-bottom: 1px solid #8b8b8b;
  
    border-top: none;
    border-left: none;
    border-right: none;
  
    background-image: url("/src/assets/search.png");
    background-size: 19.47px;
    background-repeat: no-repeat;
    background-position: 5px center;
  }

  .searchbar.darkModeContainer > div > div > input {
    background-image: url("/src/assets/searchdark.png");
    border-bottom: 1px solid #9CA9B9;
    color: #F0F0F0;
  }
  
  .search:hover,
  .search:focus{
    border-bottom: 2px solid #000000;
  }

  .search.darkModeContainer:hover,
  .search.darkModeContainer:focus{
    border-bottom: 2px solid #F0F0F0;
    /* border-bottom: 2px solid #8b8b8b; */
  }
  /* .searchbar > div > div  > input:active {
    border-bottom: 2px solid #000000;
  } */
  
  .searchbar > div > div > input[type="search"] {
    /* border: none;
    background-color: transparent; */
    /* margin-left: 32px; */
    padding: 7px 8px 7px 30px;
    /* font-size: 16px; */
    /* color: inherit;
    border: 1px solid black; */
    border-radius: inherit;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* height: 40px; */
    /* line-height: 24px; */
  }
  .searchbar > div > div > input:hover {
    outline: none;
    /* pointer-events: none; */
  }

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance:none;
  }
  
  [type="search"].search::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-image: url("/src/assets/close.svg");
    background-size: 10px 10px;
  }

  [type="search"].search.darkModeContainer::-webkit-search-cancel-button {
    background-image: url("/src/assets/closedark.svg");
  }
  
  [type="search"]::-webkit-search-cancel-button:hover {
    cursor: pointer;
  }
  
  .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 40px; */
    /* position: relative; */
  }
  
  .search-drop-container {
    height: 40px;
    /* position: relative; */
  }
  
  .dropdown {
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    position: absolute;
    cursor: pointer;
    /* width: 33vw; */
    z-index: 3;
    /* margin-left: 32px; */
    width: 201px;
    max-height: 300px;
    overflow-y: auto;
  
    /* position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none; */
    /* max-width: 160px; */
    /* padding: 8px 0; */
    /* background-color: #f1f1f1; */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    border: none;
    border-radius: 4px;
  }

  .dropdown.darkModeContainer {
    background-color: #272835;
  }
  
  .dropdown-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* width: 100%; */
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #F9F9F9;
    z-index:10;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }

  .dropdown-row.darkModeContainer {
    background-color: #272835;
    color: #F0F0F0;
    /* background-color: #1C1C28; */
  }
  
  .dropdown-symbol{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
  }
  
  .dropdown-shortname{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
  }

  .dropdown-row:hover {
    background-color: #f1f1f1;
  }

  .dropdown-row.darkModeContainer:hover {
    background-color: #1C1C28;
  }
  
  
  .quick-price {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-left: 32px; */
    /* color: #068B03; */
  
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
    border-radius: 4px;
    height: 28px;
  }
  
  .main-quick-price {
    margin-top: 0;
    margin-left: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    
    /* text-align: right; */
    
    /* Semantic/Success */
    
    /* color: #757D87; */
    color: #000000;
  }

  .main-quick-price.darkModeContainer {
    color: #F0F0F0
  }
  
  .secondary-price {
    margin-top: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-left: 8px;
    color: #757D87;
  }