.analytics {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100dvw;
}

.analytics-left {
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.analytics-right {
  padding: 24px;
}

.tab-heading {
  width: 100%;
  height: 56px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  display: flex;
  align-items: flex-end;
  color: #757d87;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 24px 0px;
}

.tab-heading.darkModeContainer {
  color: #c6cfda;
}

/* .analytics-left::-webkit-scrollbar {
  background: transparent;
}

.analytics-left:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.analytics-left::-webkit-scrollbar-corner {
  background-color: transparent;
}

.analytics-right::-webkit-scrollbar {
  background: transparent;
}

.analytics-right:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.analytics-right::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.analytics-tab-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px 0px 12px 0px;
}

.analytics-tab-container > button {
  margin: 0px 24px 0px 24px;
}

.form-search-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 0px;
}

.form-container-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 14px;
}

.expanded-options {
  width: 100%;
}

.analytics-button-container {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.submit-button {
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #f7f9fc;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 100%;
  height: 32px;

  background: #0b5dd7;
  border-radius: 8px;
}

.submit-button.darkModeContainer {
  color: #f0f0f0;
  background: #387adb;
}

.submit-button:hover {
  cursor: pointer;
  background-color: #07306d;
}

.submit-button:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.submit-button.darkModeContainer:disabled {
  background: #c6cfda;
}

.analytics-status {
  width: 298px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#clear-all {
  margin-left: 20px;
}

#clear-all:hover {
  color: #0b5dd7;
}

#show-button {
  margin-top: 22px;
}

#show-button:hover {
  color: #0b5dd7;
}

.chips-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#menu-item {
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
}

.analytics-button {
  margin: 0px;
  padding: 0px;
  background: transparent;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #acb4be;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.analytics-button:hover {
  cursor: pointer;
}

.analytics-button-selected {
  border: none;
  background: transparent;
  margin: 0px;
  padding: 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #0b5dd7;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.analytics-button-selected.darkModeContainer {
  color: #387adb;
}

.analytics-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0px;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  background-color: #ffffff;
}

.analytics-form.darkModeContainer {
  background-color: #272835;
}

.analytics-form::-webkit-scrollbar {
  display: none;
}

.tradingview-widget-container {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  /* padding: 24px; */
  margin-bottom: 1px;
  border-radius: 20px;
  box-sizing: border-box;
}

.tradingview-widget-container.darkModeContainer {
  /* background-color: #272835; */
  background-color: #131722;
}

.more-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  /* height: 100%; */
  /* padding-bottom: 12px; */
  /* margin-top: 12px; */

  font-family: "Inter";
  color: #0b5dd7;
}

.more-button:hover {
  cursor: pointer;
}

.more-button.darkModeContainer {
  color: #387adb;
}

.more-button-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 64px;
  /* height: 100%; */
  /* padding-bottom: 12px; */
  /* margin-top: 12px; */

  font-family: "Inter";
  color: #0b5dd7;
}

.more-button-2:hover {
  cursor: pointer;
}

.more-button-2.darkModeContainer {
  color: #387adb;
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  .analytics {
    flex-direction: column;
    overflow-y: scroll;
  }

  .analytics-left {
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px;
  }

  .analytics-right {
    width: calc(100% - 78px);
    box-sizing: border-box;
  }

  .analytics-form {
    padding: 24px 12px;
  }

  .tradingview-widget-container {
    height: 100vw;
  }
}

@media screen and (min-width: 851px) {
  .analytics-left {
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px;
  }

  .analytics-right {
    width: calc(100% - 408px - 78px);
    box-sizing: border-box;
    overflow-y: scroll;
    height: auto;
  }

  .analytics-form {
    padding: 24px;
  }
}

@media screen and (max-width: 480px) {
  .analytics-left {
    width: 100%;
  }

  .analytics-right {
    width: 100%;
    overflow-y: scroll;
    height: auto;
  }

  .analytics-form {
    padding: 24px 12px;
  }
}
