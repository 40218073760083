.logo:hover {
  cursor: pointer;
}

.profile-icon:hover {
  cursor: pointer;
}

.profile-dropdown {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  /* border: 1px solid gray; */
  position: absolute;
  cursor: pointer;

  z-index: 10;
  right: 19px;
  top: 60px;

  /* background-color: #f1f1f1; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 4px;
}

.dropdown-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 100%; */
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f9f9f9;
  /* background-color: #272835; */
  z-index: 10;
  padding: 8px 16px;
  /* color: #F0F0F0; */
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.dropdown-row:hover {
  background-color: #f1f1f1;
}

.navbar {
  z-index: 3;
}

.navbar.darkModeContainer {
  background-color: #272835;
  color: #f0f0f0;
  /* transition: background-color 0.3s ease; */
}

.dropdown-row.darkModeContainer {
  background-color: #272835;
  color: #f0f0f0;
}

.profile-dropdown.darkModeContainer {
  background-color: #272835;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-row:hover.darkModeContainer {
  background-color: #232339;
}

.button-container {
  /* width: 40px;
  height: 40px; */

  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: flex-end; */

  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  /* align-items: center; */
  padding: 8px 16px;
  width: 86px;
  height: 40px;
  background-color: #f7f9fc;
  border-radius: 8px;
  border: 1px solid #0f54bb;
  color: #0f54bb;
}

/* .button-container.darkModeContainer:hover {
  color: #f0f0f0;
  background-color: #387adb;
} */

.button-container:hover {
  cursor: pointer;
  color: #f7f9fc;
  background-color: #0f54bb;
}

@media screen and (min-width: 481px) {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 76px;
    width: 100vw;
    left: 0px;
    right: 0px;
    top: 0px;
    /* background-color: #eaecf1; */
  }

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: absolute;
    width: 197px;
    height: 40px;
    left: 24px;
    top: 18px;
  }

  /* .logo:hover{
        filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45));
      } */

  .button-container {
    position: absolute;
    right: 24px;
    top: 18px;
  }

  .profile-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 32px;
    top: 18px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  /* .profile-icon:hover{
        filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45));
      } */

  .profile-dropdown {
    width: 100px;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 63px;
    width: 100dvw;
    left: 0px;
    right: 0px;
    top: 0px;
    /* border: 10px solid #f0f0f0; */
    /* position: relative; */
    /* background-color: #eaecf1; */
  }

  .logo {
    display: flex;
    margin-left: 24px;
    margin-top: 8px;

    /* position: absolute; */
    /* width: 197px;
        height: 40px; */
    /* left: 12px;
        top: 13px; */
  }

  .profile-icon {
    display: flex;
    margin-right: 24px;
    width: 40px;
    height: 40px;
    flex-direction: row;
    align-items: flex-end;
  }

  .button-container {
    /* display: flex; */
    margin-right: 24px;
    margin-top: 8px;
    /* flex-direction: column;
    align-items: center;
    justify-content: center; */
  }

  .profile-dropdown {
    width: 102px;
  }

  .tab-name {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    /* width: 100dvw; */
    height: 63px;
    margin: 0;
    z-index: 0;

    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    /* color: #0F54BB; */
  }

  /* .tab-name.darkModeContainer {
        color: #F0F0F0;
      } */
}
