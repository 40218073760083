.chart-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
}

.chart-shortname {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #757D87;
  /* margin-top: 8px; */
}

.symbol-price {
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-items: top;
}

.h4-watchlist {
  margin: 0;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #000000;
}

.h4-watchlist.darkModeContainer {
  color: #F0F0F0;
}

.chart-main-price {
  margin: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.chart-main-price.darkModeContainer {
  color: #F0F0F0;
}

.chart-secondary-price {
  margin: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 8px;    
  color: #068B03;
}

.chart-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 323px;
  overflow: auto;
  height: 48px;
}

.chart-buttons > button {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  border: none;
  background: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 100%;
  /* max-width: 53.83px; */
  height: 100%;
}

.chart-buttons > button:hover {
  cursor: pointer;
}

.chart-buttons::-webkit-scrollbar {
  display: none;
}

/* .chart-buttons::-webkit-scrollbar {
  background: transparent;
}

.chart-buttons:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.chart-buttons::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.chart {
  display: flex;
  flex-direction: column;
  height:auto;
  color: #000000;
  background: #FFFFFF;
  border-radius: 20px;
}

.chart.darkModeContainer {
  background: #272835;
}

.chart-quote {
  display: flex;
  flex-direction: column;
}

.chart-quick-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 12px;
}

@media screen and (min-width: 481px){
  .chart {
    padding: 24px;
  }
}

@media screen and (max-width: 480px){
  .chart {
    padding: 24px 12px;
  }

}