.chart {
  border-radius: 20px;
  width: 100%;
  background: #FFFFFF;
}

.chart.darkModeContainer {
  background: #272835;
}

.analytics-stock-header{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 4px;

  padding: 0;

  color: #ACB4BE;
  background: transparent;
  border: none;
}

.analytics-stock-header:hover{
  cursor: pointer;
}

.analytics-stock-header-container{
  margin: 14px 0px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

.analytics-stock-header-container::-webkit-scrollbar {
  display: none;
}

.analytics-performance {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

@media screen and (min-width: 481px){
}

@media screen and (max-width: 480px){

}