.reset {
  background-color: #ffffff;
}

.reset.darkModeContainer {
  background-color: #272835;
}

.reset.darkModeContainer h4 {
  color: #f0f0f0;
}

.reset > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-label-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 200px;
  /* margin: 10px; */
  background: "transparent";
  /* margin-left: 10px; */
  padding-right: 10px;
}

.login-button {
  color: #f7f9fc;
  background: #0b5dd7;
}

.login-button.darkModeContainer {
  color: #f0f0f0;
  background: #387adb;
}

.login-button:hover {
  cursor: pointer;
  background-color: #07306d;
}

@media screen and (min-width: 481px) {
  .reset {
    position: fixed;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    z-index: 4;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 76px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .reset > h4 {
    margin-top: 12px;
    margin-bottom: 32px;

    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    /* line-height: 32px; */

    display: flex;
    align-items: center;
    justify-content: center;

    color: #000000;
  }

  .login-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* line-height: 17px; */

    width: 343px;
    height: 33px;

    border-radius: 8px;

    border: none;
  }
}

@media screen and (max-width: 480px) {
  .reset {
    position: fixed;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 4;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 63px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow-y: scroll;
    max-height: calc(100dvh - 189px);
  }

  .reset > h4 {
    margin-top: 0px;
    margin-bottom: 24px;
    width: 100%;
    font-family: "Roboto Condensed";
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #000000;
  }

  .login-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    width: 260px;
    height: 33px;

    border-radius: 8px;

    border: none;
  }
}
