.communities-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 20px;
  min-height: 268px;
  margin-top: 0px;
  overflow: auto;
}

.communities-header.darkModeContainer {
  background: #272835;
  color: #f0f0f0;
}

.upper-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lower-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.lower-header.darkModeContainer p {
  color: #f0f0f0;
}

.lower-header p {
  /* margin: 0px 0px 12px 12px; */
  margin: 0px;
}

.lower-header button {
  /* margin: 0px 12px 12px 0px; */
}

.button-container {
  display: flex;
  flex-direction: column;
  /* margin: 0px 12px 0px 0px; */
}

.button-container button {
  margin-bottom: 12px;
}

.text-container {
  display: flex;
  flex-direction: column;
  /* margin: 12px 0px 0px 12px; */
}

.text-container p {
  /* margin: 12px 0px 0px 0px; */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* font-size: 25.5vw; */
  color: #000000;
  /* text-align: justify; */
  /* text-justify: inter-word; */
  /* word-wrap: break-word; */
}

.text-container.darkModeContainer p {
  color: #f0f0f0;
}

.text-container h4 {
  margin: 0px;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;

  color: #000000;
}

.text-container.darkModeContainer h4 {
  color: #f0f0f0;
}

.submit-button {
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 100px;
  height: 44px;

  color: #000000;

  background: #f9f9f9;
  border-radius: 8px;
}

.submit-button-2 {
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #f7f9fc;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 100px;
  height: 44px;

  background: #0b5dd7;
  border-radius: 8px;
}

.submit-button.darkModeContainer {
  color: #387adb;
  background: #f0f0f0;
}

.submit-button-2.darkModeContainer {
  color: #f0f0f0;
  background: #387adb;
}

.submit-button:hover {
  cursor: pointer;
  color: #f0f0f0;
  background-color: #0b5dd7;
}

.submit-button.darkModeContainer:hover {
  background-color: #387adb;
}

.submit-button-2:hover {
  cursor: pointer;
  background-color: #07306d;
}

.submit-button:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}
.submit-button:disabled:hover {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.submit-button-2:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}
.submit-button-2:disabled:hover {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.edit-text {
  display: flex;
  flex-direction: row;
  color: #0b5dd7;
  margin-top: 0px;
  cursor: pointer;
}

.edit-text.darkModeContainer {
  color: #387adb;
}

@media screen and (min-width: 481px) {
  .communities-header {
    padding: 24px;
  }
  .text-container {
    max-width: 500px;
  }
}

@media screen and (max-width: 480px) {
  .communities-header {
    padding: 24px 12px;
  }
  .text-container {
    max-width: 60%;
  }
}
