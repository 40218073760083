.trade-position {
  background: #FFFFFF;
}

.trade-position.darkModeContainer {
  background: #272835;
  color: #F0F0F0;
}

.trade-position thead th {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #E0E2EA;
  }
  
  .trade-position tbody td {
    padding: 12px;
    border-bottom: 1px solid #E0E2EA;
  }

.trade-position-container {
  overflow: auto;
}

/* .trade-position-container::-webkit-scrollbar {
  background: transparent;
}

.trade-position-container:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.trade-position-container::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.trade-position-container.darkModeContainer table thead tr th{
  color: #F0F0F0;
}

@media screen and (min-width: 481px){
    .trade-position {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        /* padding: 32px; */
        border-radius: 20px;
        width: calc(100vw - 505px);
    }
    
    .trade-position-container {
      margin: 32px;
    }
    
    .trade-position table {
      width: 100%;
      border-collapse: collapse;
    }
}

@media screen and (max-width: 480px){

    .trade-position {
        display: flex;
        flex-direction: column;
        margin-top: 32px;
        /* padding: 32px; */
        border-radius: 20px;
        /* width: calc(100vw - 505px); */
    }
    
    .trade-position-container {
        margin: 0px 12px 24px 12px;
    }

    .trade-position-container > h6 {
        margin: 24px 0px 0px 0px;
    }
    
    .trade-position table {
      /* width: 100%; */
      border-collapse: collapse;
    }
}