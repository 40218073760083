.trade-history thead th {
  padding: 12px;
  text-align: center;
  white-space: nowrap;
}

.trade-history thead th:first-child{
  text-align: left;
}

.trade-history tbody td {
  padding: 12px;
  text-align: center;
}
  
  .trade-history tbody tr:nth-child(odd) {
    background: #F0F0F0;
  }

  .trade-history.darkModeContainer {
    background: #272835;
    color: #F0F0F0;
  }

  .trade-history.darkModeContainer > .trade-history-container > table > thead > tr > th {
    color: #F0F0F0;
  }
  
  .trade-history.darkModeContainer tbody tr:nth-child(odd) {
    background: #1C1C28;
  }

  .trade-history-container {
    overflow: auto;
     /* -webkit-overflow-scrolling: touch;  */
  /* overscroll-behavior: contain; */
  /* -webkit-transform: translateZ(0); */ 
  transform: translateZ(0); /* place scrollbar on top of sticky cols/rows*/
}
  
  /* .trade-history-container::-webkit-scrollbar {
    background: transparent;
  }
  
  .trade-history-container:hover::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }

  .trade-history-container::-webkit-scrollbar-corner {
    background-color: transparent;
  } */

  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .action-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
  }

  .time-red-dark {
    color: #C54242; /* Dark mode red */
  }
  
  .time-red-light {
    color: #F42727; /* Light mode red */
  }
  
  .trade-history {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    /* padding: 24px 12px; */
    background: #FFFFFF;
    border-radius: 20px;
    max-height: 320px;
    }
  
    .trade-history > h6 {
      margin-bottom: 12px;
    }

@media screen and (min-width: 481px){
      .trade-history table {
        width: 100%;
        border-collapse: collapse;
      }
      
      .trade-history thead th {
        min-width: calc(100dvw/15);
      }
}

@media screen and (max-width: 480px){
    .trade-history thead th {
      min-width: calc(100dvw/4.6);
    }
}