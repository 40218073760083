.alert-message {
  background-color: #FFFFFF;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 3;
  box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
}

.alert-message.darkModeContainer {
  background-color: #272835;
  color: #F0F0F0;
}

.alert-message p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #000000;

  margin-top: 0;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.alert-message.darkModeContainer p {
  color: #F0F0F0;
}

.button-container{
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 100px;
  height: 44px;
  background-color: #F0F0F0;
  border-radius: 8px;
}

.button-container.darkModeContainer {
  color: #387ADB;
}

.button-container.darkModeContainer:hover {
  color: #F0F0F0;
  background-color: #387ADB;
}

.button-container:hover {
  cursor: pointer;
  color: #F0F0F0;
  background-color: #0B5DD7
}

@media screen and (min-width: 481px){
  .alert-message {
    width: 340px;
    height: 168px;
    top: 25dvh;
    padding: 20px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 480px){
  .alert-message {
    width: 340px;
    height: auto;
    top: 63px;
    padding: 20px;
    box-sizing: border-box;
  }
}