.analytics-more {
overflow: auto;
}

.analytics-stock-header{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 4px;
  margin-bottom: 12px;
  padding: 0;

  color: #ACB4BE;
  background: transparent;
  border: none;
}
  
.analytics-stock-header:hover{
  cursor: pointer;
}

.frequency-header {
  color: #000000;
}

.frequency-header.darkModeContainer {
  color: #F0F0F0;
}

.matrix-individual-container {
  margin-top: 16px;
  overflow: auto;
}

/* .matrix-individual-container::-webkit-scrollbar {
  background: transparent;
}

.matrix-individual-container:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.matrix-individual-container::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.analytics-stock-header-container{
  margin: 14px 0px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

.analytics-stock-header-container::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 481px){
.analytics-stock-header-container{
  /* margin: 32px 32px 0px 32px; */
}
.matrix-container {
  /* margin: 0px 32px 0px 32px; */
}
}

@media screen and (max-width: 480px){
.analytics-stock-header-container{
  /* margin: 22px 12px 0px 12px; */
}

.matrix-container {
  /* margin: 0px 12px 0px 12px; */
}
}