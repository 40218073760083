.communities-discussion {
    color: #000000;
    min-height: 100px;
}

.communities-discussion.darkModeContainer {
    color: #F0F0F0;
}

.post-item {
    /* margin-left: 12px;
    margin-right: 12px; */
    margin-bottom: 12px;
    padding: 12px;
    border-radius: 10px;
}

.post-item:last-child {
    margin-bottom: 0px;
}

.post-item:hover{
    background-color: #F0F0F0; 
    cursor: pointer;
}

.post-item.darkModeContainer:hover {
    background-color: #1C1C28; 
}

.post-username {
    font-family: "Roboto Condensed";
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.post-username:hover {
    color: #0B5DD7;
}

.post-username.darkModeContainer:hover {
    color: #387ADB;
}

.user-and-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.post-date {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #757D87;
    margin: 0px;
}

.post-title {
    font-family: "Roboto";
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 8px 0px 0px 0px;
}
.post-description {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px;
}

.icon-group {
    display: flex;
    flex-direction: row;
}

.icon-and-number {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
    margin-top: 12px;
    width: 24px;
    height: 32px;
}

.icon-and-number p {
    font-size: 12px;
}

.pin-more {
    display: flex;
    flex-direction: row;
}

.more-ref {
    position: relative;
}

.more-dropdown {
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    /* border: 1px solid gray; */
    position: absolute;
    cursor: pointer;
    
    z-index: 10;
    width: 80px;
    right: 0px;
    /* top: 60px; */
    
    /* background-color: #f1f1f1; */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border: none;
    border-radius: 4px;
}

.dropdown-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* width: 100%; */
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #F9F9F9;
    /* background-color: #272835; */
    z-index:10;
    padding: 8px 16px;
    /* color: #F0F0F0; */
    text-decoration: none;
    cursor: pointer;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }
  
  .dropdown-row:hover {
    background-color: #f1f1f1;
  }

  .dropdown-row.darkModeContainer {
    background-color: #272835;
    color: #F0F0F0;
  }

  .more-dropdown.darkModeContainer {
    background-color: #272835;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }

  .dropdown-row:hover.darkModeContainer{
    background-color: #232339;
  }

  .placeholder-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    /* font-size: 10px; */
    line-height: 12px;
    color: #757D87;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

@media screen and (min-width: 481px){

}

@media screen and (max-width: 480px){

}
