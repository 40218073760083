.communities-alert-message {
  background-color: #ffffff;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 3;
  box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
}

.communities-alert-message.darkModeContainer {
  background-color: #272835;
  color: #f0f0f0;
}

.communities-alert-message p {
  /* margin-bottom: 4px; */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 0px 0px 24px 0px;
  text-align: center;
  /* line-height: 24px; */

  color: #000000;
}

.communities-alert-message.darkModeContainer p {
  color: #f0f0f0;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  /* width: 120px; */
  /* margin-top: 24px; */
}

.button-container button {
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  width: 100px;
  height: 44px;

  /* color: #000000; */

  border-radius: 8px;
}

.button-container button:hover {
  cursor: pointer;
}

.button-red {
  color: #f42727;
  background: #f9f9f9;
}

.button-red:hover {
  color: #f9f9f9;
  background: #f42727;
}

.button-red.darkModeContainer {
  color: #c54242;
  background: #f0f0f0;
}

.button-red.darkModeContainer:hover {
  color: #f0f0f0;
  background: #c54242;
}

.button-blue {
  color: #0b5dd7;
  background: #f9f9f9;
}

.button-blue:hover {
  color: #f9f9f9;
  background: #0b5dd7;
}

.button-blue.darkModeContainer {
  color: #387adb;
  background: #f0f0f0;
}

.button-blue.darkModeContainer:hover {
  color: #f0f0f0;
  background: #387adb;
}

@media screen and (min-width: 481px) {
  .communities-alert-message {
    width: 328px;
    padding: 32px;
  }
}

@media screen and (max-width: 480px) {
  .communities-alert-message {
    width: 260px;
    padding: 24px;
  }
}
