/* Default styles */
.matrix-table {
    margin-bottom: 0px;
    margin-top: 12px;
    border-spacing: 0;
    border-collapse: collapse;
    /* overflow: auto; */
    position: relative;
    color: #000000;
    /* max-width: 200px; */
}

.matrix-table.darkModeContainer {
    color: #F0F0F0;
}

.matrix-table > thead {
    border: none;
}

.matrix-table > thead > tr {
    border: none;
}

.matrix-table-header {
    border: 1px solid #CED5DE;
    background-color: #F0F0F0;
    /* max-width: 50px; */
}

.matrix-table-header.darkModeContainer {
    color: #272835;
    background-color: #9CA9B9;
}

.matrix-table-content {
    border: 1px solid #CED5DE;
    /* max-width: 30px;
    width: 30px; */
}

.highlighted {
    background-color: #ffa;
}

/* Dark mode styles for highlighted elements */
.matrix-table.darkModeContainer .highlighted {
    color: #272835;
    background-color: #D98A17;
}
