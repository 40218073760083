.reset {
  background-color: #ffffff;
}

.reset > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  margin-top: 7px;
  margin-bottom: 32px;
  height: 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #000000;
}

.reset > form > p > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #007aff;
}

.reset > form > p > span:hover {
  cursor: pointer;
}

.reset-button:hover {
  cursor: pointer;
  background-color: #07306d;
}

.reset-button-alt:hover {
  cursor: pointer;
  background-color: #07306d;
}

.reset-button:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.reset-button-alt:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.forgot-password {
  width: 100%;
  margin-bottom: 12px;
  margin-top: -5px;
  margin-left: 32px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.forgot-password-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #007aff;
  text-decoration: none;
}

.forgot-password-link:hover {
  cursor: pointer;
  /* text-decoration: underline;  */
}

.pass-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resend {
  display: inline-block;
  width: 32px;
  text-align: left;
  justify-content: left;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #007aff;
}

@media screen and (min-width: 481px) {
  .reset {
    position: absolute;
    padding: 32px;
    /* width: 487px; */
    /* height: 348px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 223px;
  }

  .reset > h4 {
    margin-top: 12px;
    margin-bottom: 32px;

    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #000000;
    /* width: 343px; */
  }

  .reset-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #f7f9fc;

    width: 100%;
    height: 33px;

    /* margin-bottom: 12px; */
    /* margin-top: 12px; */

    background: #0b5dd7;
    border-radius: 8px;

    border: none;
  }

  .reset-button-alt {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #f7f9fc;

    height: 33px;

    /* margin-bottom: 12px; */
    /* margin-top: 12px; */

    background: #0b5dd7;
    border-radius: 8px;

    border: none;

    width: 260px;
    margin-right: 38.55px;
    margin-left: 38.55px;
  }

  /* .login {
    margin-bottom: 20px;
  } */
}

@media screen and (max-width: 480px) {
  .reset {
    position: absolute;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 87px;
  }

  .reset > h4 {
    margin-top: 0px;
    margin-bottom: 24px;
    width: 100%;
    font-family: "Roboto Condensed";
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #000000;
  }

  .reset-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #f7f9fc;

    width: 100%;
    height: 33px;
    /* margin-bottom: 12px; */
    /* margin-top: 12px; */

    background: #0b5dd7;
    border-radius: 8px;

    border: none;
  }

  .reset-button-alt {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #f7f9fc;

    height: 33px;
    /* margin-bottom: 12px; */
    /* margin-top: 12px; */

    background: #0b5dd7;
    border-radius: 8px;

    border: none;
    width: 260px;
    margin-right: 4.55px;
    margin-left: 4.55px;
  }
  /* 
  .login {
    margin-bottom: 12px;
  } */
}
