/* EDIT PROFILE */
.communities-create {
  background-color: #ffffff;
}

.communities-create.darkModeContainer {
  background-color: #272835;
  color: #f0f0f0;
}

.communities-create > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-normal-text {
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
}

.edit-password-fields {
  margin-top: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-password-click:hover {
  cursor: pointer;
}

.signup-button {
  background: #0b5dd7;
  color: #f7f9fc;
}

.signup-button.darkModeContainer {
  background: #387adb;
  color: #f0f0f0;
}

.signup-button:hover {
  cursor: pointer;
  background-color: #07306d;
}

.signup-bottom-alt {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin: 12px 10.5px 0px 4.55px;
}

.signup-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  /* margin: 12px 10.5px 0px 4.55px; */
  margin-top: 4px;
}

.signup-bottom-alt p {
  margin: 0px;
}

.signup-bottom-alt p:hover {
  cursor: pointer;
}

.custom-label-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 200px;
  /* margin: 10px; */
  background: "transparent";
  /* margin-left: 10px; */
  padding-right: 10px;
}

.suretext {
  display: flex;
  flex-direction: row;
}

.suretext p {
  padding: 8px;
}

#no-pointer:hover {
  cursor: default;
}

.red-text {
  color: #f42727;
}

.blue-text {
  color: #0b5dd7;
}

.red-text.darkModeContainer {
  color: #c54242;
}

.blue-text.darkModeContainer {
  color: #387adb;
}

.word-count {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #757d87;
  margin-top: -4.55px;
}

@media screen and (min-width: 481px) {
  .communities-create {
    position: fixed;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    z-index: 3;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 76px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .communities-create > h4 {
    margin-top: 12px;
    margin-bottom: 32px;
    font-family: "Roboto Condensed";
    font-size: 28px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .edit-password-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }

  .edit-password-header span {
    padding-left: 4.55px;
    padding-right: 4.55px;
  }

  .edit-password-fields span {
    padding-left: 4.55px;
    padding-right: 4.55px;
  }

  .edit-password-click {
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    padding-left: 55px;
    padding-right: 55px;
  }

  .signup-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    width: 111px;
    height: 54px;
    /* left: 298px;
    top: 630px; */

    border-radius: 8px;
    border: none;
  }

  .signup-bottom {
    width: 328px;
    /* margin-top: 15.5px; */
  }
  .word-count {
    width: 328px;
  }
}

@media screen and (max-width: 480px) {
  .communities-create {
    position: fixed;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 3;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 63px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow-y: scroll;
    max-height: calc(100dvh - 189px);
  }

  .communities-create > h4 {
    margin-top: 0px;
    margin-bottom: 24px;

    font-family: "Roboto Condensed";
    font-size: 28px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .edit-password-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin: 12px 0px;
  }

  .edit-password-header span {
    padding-left: 4.55px;
    padding-right: 4.55px;
    /* padding-right: 32px; */
  }

  .edit-password-fields span {
    padding-left: 4.55px;
    padding-right: 4.55px;
  }

  .edit-password-click {
    font-family: "Roboto";
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    padding-left: 4.55px;
    padding-right: 4.55px;
  }

  .signup-button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;

    width: 80px;
    height: 48px;
    /* left: 298px;
        top: 630px; */

    border-radius: 8px;
    border: none;
  }

  .signup-bottom {
    /* justify-content: space-between; */
    /* margin-top: 12px; */

    width: 260px;
  }
  .word-count {
    width: 260px;
  }
}
