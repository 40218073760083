.communities-invite {
  background-color: #ffffff;
}

.communities-invite.darkModeContainer {
  background-color: #272835;
  color: #f0f0f0;
}

.clipboard {
  margin-left: 12px;
  margin-right: 12px;
}

.clipboard:hover {
  cursor: pointer;
}

.access-code {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.access-icon {
  position: relative;
}

.tooltip {
  position: absolute;
  top: -45px; /* Adjust the positioning as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  white-space: nowrap;
  z-index: 1; /* Ensure it appears above other elements */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 6px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  transform: translateX(-50%);
}

.access-code p {
  /* margin: 0px; */
  margin-left: 12px;
}

.access-code {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 10px;
}

.access-code:hover .tooltip {
  opacity: 1;
}

.message-container {
  margin-top: 0;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* max-width: 247px; */
}

@media screen and (min-width: 481px) {
  .communities-invite {
    position: fixed;
    /* width: 487px;
        height: 268px; */
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 3;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 76px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .message-container {
    text-wrap: nowrap;
  }
}

@media screen and (max-width: 480px) {
  .communities-invite {
    position: fixed;
    /* width: 100%; */
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 3;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 63px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 20px;
    box-sizing: border-box;
  }
}
