.report{
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  padding: 24px;
  border-radius: 20px;
  background: #FFFFFF;
  overflow: auto;
}

.report.darkModeContainer {
  background: #272835;
}

.report-top{
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
}

.h4-watchlist {
  margin: 0;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;

  color: #000000;
}

.h4-watchlist.darkModeContainer {
  color: #F0F0F0;
}

.analytics-tab-header {
  height: 48px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 16px;
  margin: 0;

  background: transparent;
  border: none;  

  color: #000000;
}

.analytics-tab-header.darkModeContainer {
  color: #F0F0F0;
}

.analytics-tab-header-container{
  /* margin: 24px 12px 0px 12px; */
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: none;
}

.analytics-tab-header-container::-webkit-scrollbar {
  display: none;
}

.portfolio-report-content {
/* flex: 1;
overflow: auto;  */
/* height: 100%; */
display: flex;
flex-direction: column;
height: calc(100% - 76.5px);
}

.analytics-tab-header.active {
box-shadow: inset 0 -4px 0 0 #0B5DD7, 0 0 0 1px #0B5DD7;
}

.analytics-tab-header:hover{
cursor: pointer;
/* border-bottom: 1px solid #F4F9FF; */
}

.report-top{
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  /* margin: 12px 0px 0px 12px; */
}


/* .analytics-tab-header-container::-webkit-scrollbar {
  background: transparent;
}

.analytics-tab-header-container:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.analytics-tab-header-container::-webkit-scrollbar-corner {
  background-color: transparent;
} */




@media screen and (min-width: 481px){
  .report{
    padding: 24px;
  }

  .analytics-tab-header {
    width: 202px;   
  }

  .report-table-row td {
    min-width: calc(100dvw/15);
  }

}

@media screen and (max-width: 480px){
  .report{
    padding: 24px 12px;
  }

  .analytics-tab-header {
    width: 164px;   
  }

  .report-table-row td {
    min-width: calc(100dvw/6.3);
  }

}
