.portfolio-chart {
  display: flex;
  flex-direction: column;
  height:auto;
  color: #000000;
  background: #FFFFFF;
  border-radius: 20px;
}

.portfolio-chart.darkModeContainer {
  color: #F0F0F0;
  background: #272835;
}

  .chart-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 323px;
    overflow: auto;
    height: 48px;
  }

  .chart-buttons > button {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  
    border: none;
    background: none;
  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;

    width: 100%;
    /* max-width: 53.83px; */
    height: 100%;
  }
  
  .chart-buttons > button:hover {
    cursor: pointer;
  }

  .chart-buttons::-webkit-scrollbar {
    display: none;
  }
  
  /* .chart-buttons::-webkit-scrollbar {
    background: transparent;
  }
  
  .chart-buttons:hover::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
  
  .chart-buttons::-webkit-scrollbar-corner {
    background-color: transparent;
  } */

  h6 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    border: none;
    background: none;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    /* padding: 8px 16px;
    width: 53.83px;
    height: 48px; */
    /* flex: none; */
    /* order: 0;
    flex-grow: 1; */
  }

  .portfolio-chart-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 12px;

  }

  @media screen and (min-width: 481px){
    .portfolio-chart {
        padding: 24px;
      }
  }

  @media screen and (max-width: 480px){
    .portfolio-chart {
      padding: 24px 12px;
    }
  }