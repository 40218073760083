/* GLOBAL SIDEBAR */
.sidebar-button {
  /* position: absolute; */
  /* width: 42px;
  height: 42px; */
  left: 0px;
  top: 0px;
  /* Fixed Footer */
  /* filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.16)); */
  /* overflow: hidden; */
  /* border: 20px solid transparent; */
  /* border-bottom: 3px solid transparent; */
}

.sidebar {
  background: #d7840b;
  /* background: #f7f9fc; */
  /* background: #eaecf1; */
  overflow: auto;
}

.sidebar.darkModeContainer {
  background: #d98a17;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-button-subgroup {
  display: flex;
  align-items: center;
}

.button-adornment {
  position: absolute;
  border: 2px solid #000000;
}

.button-adornment.darkModeContainer {
  border: 2px solid #1c1c28;
}

@media screen and (min-width: 481px) {
  .sidebar {
    position: absolute;
    width: 78px;
    left: 0px;
    top: 0px;
    bottom: 0px;

    /* Secondary */

    border-radius: 0px 20px 0px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .sidebar-button-group {
    /* position: relative; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    height: 306px;
    margin-top: 0px;
    /* overflow: hidden; */
    /* border-bottom: 3px solid transparent; */
  }

  .sidebar-button:hover {
    /* display: block;
    position: absolute; */
    /* transform: scale(1.2); */
    /* width: 38px;
    height: 38px; */
    left: 0px;
    top: 0px;
    /* filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45)); */
    cursor: pointer;
    /* border-bottom: 3px solid transparent; */
    /* overflow: hidden; */
  }

  .sidebar-button-clicked {
    /* display: block;
    position: absolute; */
    /* transform: scale(1.2); */
    /* width: 42px;
    height: 42px; */
    left: 0px;
    top: 0px;
    /* filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45)); */
    cursor: pointer;
    /* border-bottom: 3px solid transparent; */
    /* overflow: hidden; */
  }

  .button-adornment {
    height: 42px;
    left: 0px;
    border-radius: 0px 21px 21px 0px;
  }
}
@media screen and (max-width: 480px) {
  .sidebar {
    /* position: absolute; */
    height: 63px;
    left: 0px;
    top: 0px;
    bottom: 0px;

    /* Secondary */

    border-radius: 20px 20px 0px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* z-index: 10; */
  }

  .sidebar-button-clicked {
    /* filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.45)); */
  }

  .sidebar-button-group {
    /* position: relative; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    /* height: 306px; */
    width: 100%;
    /* margin-top: 12px; */
    /* height: 100%; */
    /* overflow: hidden; */
    /* border-bottom: 3px solid transparent; */
  }

  /* .sidebar > img {
    margin-bottom: 32px;
  } */

  .tab-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-4px);
  }

  .sidebar-button-subgroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 51px;
    height: 63px; */
  }

  .button-adornment {
    width: 42px;
    bottom: 0px;
    border-radius: 21px 21px 0px 0px;
  }

  /* .tab-sub-container > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } */
}
