  .trade-history {
    background: #FFFFFF;
  }

  .trade-history.darkModeContainer {
    background: #272835;
    color: #F0F0F0;
  }
  
  .trade-history thead th {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #E0E2EA;
  }
  
  .trade-history tbody tr:nth-child(odd) {
    background: #f0f0f0;
  }

  .trade-history.darkModeContainer tbody tr:nth-child(odd) {
    background: #1C1C28;
  }
  
  .trade-history tbody td {
    padding: 12px;
    border-bottom: 1px solid #E0E2EA;
  }
  
  .trade-history tbody tr td:last-child {
    white-space: nowrap;
  }
  
  .trade-history tbody tr:last-child td {
    border-bottom: none;
  }

  .trade-history-container {
    overflow: auto;
  }

  /* .trade-history-container::-webkit-scrollbar {
    background: transparent;
  }
  
  .trade-history-container:hover::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
  
  .trade-history-container::-webkit-scrollbar-corner {
    background-color: transparent;
  } */

  .trade-history-container.darkModeContainer table thead tr th{
    color: #F0F0F0;
  }

@media screen and (min-width: 481px){
    .trade-history {
        display: flex;
        flex-direction: column;
        margin-bottom: 96px;
        margin-top: 32px;
        border-radius: 20px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: calc(100vh - 400px);
        width: calc(100vw - 505px);
      }

      .trade-history-container {
        margin:32px;
      }
      
      .trade-history table {
        width: 100%;
        border-collapse: collapse;
      }
}

@media screen and (max-width: 480px){
    .trade-history {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
        margin-top: 32px;
        border-radius: 20px;
        overflow-y: auto; /* Enable vertical scrolling */
        max-height: 425px;
        /* width: calc(100vw - 505px); */
      }

      .trade-history-container > h6 {
        margin: 24px 0px 0px 0px;
    }

      .trade-history-container {
        margin: 0px 12px 24px 12px;
      }
      
      .trade-history table {
        /* width: 100%; */
        border-collapse: collapse;
      }
}