.trade-history {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 20px;
}

.trade-history > h6 {
  margin-bottom: 12px;
}

.trade-history thead th {
  padding: 12px;
  text-align: center;
  white-space: nowrap;
}

.trade-history thead th:first-child {
  text-align: left;
}

.trade-history tbody td {
  padding: 12px;
  text-align: center;
}

.trade-history tbody tr:nth-child(odd) {
  background: #f0f0f0;
}

.trade-history.darkModeContainer {
  background: #272835;
  color: #f0f0f0;
}

.trade-history.darkModeContainer
  > .trade-history-container
  > table
  > thead
  > tr
  > th {
  color: #f0f0f0;
}

.trade-history.darkModeContainer tbody tr:nth-child(odd) {
  background: #1c1c28;
}

.trade-history-container {
  overflow: auto;
  /* -webkit-overflow-scrolling: touch;  */
  /* overscroll-behavior: contain; */
  /* -webkit-transform: translateZ(0); */
  transform: translateZ(0); /* place scrollbar on top of sticky cols/rows*/
}

.position-name span {
  white-space: nowrap;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ticker {
  font-size: 12px;
}

.shortname {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  color: #757d87;
}

.positions-table {
  /* table-layout: fixed; */
  width: 100%;
  border-collapse: collapse;
}

.positions-table th {
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  top: 0;
  background-color: #ffffff;
}

.positions-table.darkModeContainer th {
  background-color: #272835;
}

.positions-table th:first-child {
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  top: 0;
  left: 0;
  z-index: 2;
}

.positions-table th:first-child,
.positions-table td:first-child {
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  left: 0;
  background-color: #ffffff;
}

.positions-table th:first-child::after,
.positions-table td:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1px;
  background-color: #ced5de;
}

.positions-table th:first-child {
  box-shadow: inset 0px -2px 0px 0px #ced5de;
}

.positions-table td:first-child {
  box-shadow: inset 0px -1px 0px 0px #ced5de;
}

.positions-table.darkModeContainer th:first-child,
.positions-table.darkModeContainer td:first-child {
  background-color: #272835;
}

.positions-table th:not(:first-child),
.positions-table td:not(:first-child) {
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .trade-history tbody tr td:last-child {
    white-space: nowrap;
  } */

.positions-table tbody tr:nth-child(odd) .position-name {
  background: #f0f0f0;
}

.positions-table.darkModeContainer tbody tr:nth-child(odd) .position-name {
  background: #1c1c28;
}

/* .trade-history-container::-webkit-scrollbar {
    background: transparent;
  }
  
  .trade-history-container:hover::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }

  .trade-history-container::-webkit-scrollbar-corner {
    background-color: transparent;
  } */

.date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.action-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.time-red-dark {
  color: #c54242; /* Dark mode red */
}

.time-red-light {
  color: #f42727; /* Light mode red */
}

@media screen and (min-width: 481px) {
  .trade-history {
    /* margin-top: 24px; */
    /* padding: 24px; */
    max-height: 320px;
  }

  .trade-history thead th {
    min-width: calc(100dvw / 15);
  }
}

@media screen and (max-width: 480px) {
  .trade-history {
    /* margin-top: 12px; */
    /* padding: 24px 12px; */
    max-height: 320px;
  }

  .trade-history thead th {
    min-width: calc(100dvw / 4.8);
  }
}
