.trade {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100dvw;
}

.trade-left {
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.trade-right {
  padding: 24px;
}

.tab-heading {
  width: 100%;
  height: 56px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  display: flex;
  align-items: flex-end;
  color: #757d87;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 24px 0px;
}

.tab-heading.darkModeContainer {
  color: #c6cfda;
}

.trade-searchbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 72px;
}

.bid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #068b03;
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #068B03; */
  margin-right: -20px;
  padding-left: 4px;
  /* border-radius: 19px 0 0 19px; */
  /* position: relative; */
  z-index: 1; /* Lowered z-index */
}

.bid.darkModeContainer {
  color: #272835;
  background: #87cd9d;
}

.ask {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 50%;
  height: 24px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #f42727;
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #F42727; */
  margin-left: -20px;
  padding-right: 4px;
  /* border-radius: 0 19px 19px 0; */
  /* position: relative; */
  z-index: 1; /* Lowered z-index */
}

.ask.darkModeContainer {
  color: #272835;
  background: #c54242;
}

.spread {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 16px; */
  /* text-align: center; */
  background: #ffffff;
  border-radius: 19px;
  /* position: relative;  */
  z-index: 2; /* Increased z-index to bring it in front of bid and ask */
}

.spread.darkModeContainer {
  color: #f0f0f0;
  background: #272835;
}

.custom-label-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 200px;
  /* margin: 10px; */
  background: "transparent";
  /* margin-left: 10px; */
  padding-right: 10px;
  /* color: #F0F0F0; */
}

.css-14lo706 > span {
  width: 10px;
}

.trade-totals {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
/* 
  .trade-totals {
    margin-top: 16px;
  } */

.trade-totals-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ced5de;
  margin-bottom: 8px;
  min-height: 32px;
}

.trade-totals-row > span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #757d87;
}

.trade-totals-row.darkModeContainer > span {
  color: #f0f0f0;
}

.trade-totals-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 45%;
  /* height: 33px; */
}

.trade-totals-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 45%;
  /* height: 33px; */
}

.submit-button {
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */

  /* Greyscale/White */

  color: #f7f9fc;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  /* gap: 8px; */

  height: 32px;

  /* Brand/Primary */

  background: #0b5dd7;
  border-radius: 8px;

  /* Inside auto layout */

  /* flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; */
}

.submit-button.darkModeContainer {
  color: #f0f0f0;
  background: #387adb;
}

.submit-button:hover {
  cursor: pointer;
  background-color: #07306d;
}

.submit-button:disabled {
  background: #f9f9f9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

/* .trade-right::-webkit-scrollbar{
      display: none;
  } */

.market-message {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
}

.trade-options {
  background: #ffffff;
}

.trade-options.darkModeContainer {
  background: #272835;
}

/* .trade-right::-webkit-scrollbar {
    background: transparent;
  }
  
  .trade-right:hover::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
  
  .trade-right::-webkit-scrollbar-corner {
    background-color: transparent;
  } */

/* .trade-left::-webkit-scrollbar {
    display: none;
  } */

/* .trade-left::-webkit-scrollbar {
    background: transparent;
  } */

/* .trade-left:hover::-webkit-scrollbar-thumb {
    background-color: #C1C1C1;
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
  
  .trade-left::-webkit-scrollbar-corner {
    background-color: transparent;
  } */

.trade-options::-webkit-scrollbar {
  display: none;
}

.trade-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-left: 0px;
  border-radius: 20px;
  width: 100%;
  /* height: 100%; */
  max-height: auto;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
}

.trade-options-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bid-ask {
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.more-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100%; */
  /* padding-bottom: 12px; */
  margin-top: 12px;

  font-family: "Inter";
  color: #0b5dd7;
}

.more-button:hover {
  cursor: pointer;
}

.more-button.darkModeContainer {
  color: #387adb;
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  .trade {
    flex-direction: column;
    overflow-y: scroll;
  }

  .trade-left {
    width: 408px;
    box-sizing: border-box;
  }

  .trade-right {
    width: calc(100% - 78px);
    height: auto;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px;
  }

  .trade-options {
    padding: 24px;
  }

  .trade-options-row {
    margin: 15px 0px 8px 0px;
  }

  .bid-ask {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  .submit-button {
    width: 100%;
  }
}

@media screen and (min-width: 851px) {
  .trade-left {
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px;
  }

  .trade-right {
    width: calc(100% - 408px - 78px);
    box-sizing: border-box;
    overflow-y: scroll;
    height: auto;
  }

  .trade-options {
    padding: 24px;
  }

  .trade-options-row {
    margin: 15px 0px 8px 0px;
  }

  .bid-ask {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }

  .submit-button {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .trade-left {
    width: 100%;
  }

  .trade-right {
    width: 100%;
    overflow-y: scroll;
    height: auto;
  }

  .trade-options {
    padding: 24px 12px;
  }

  .trade-options-row {
    margin: 12px 0px 0px 0px;
  }

  .bid-ask {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
  }

  .market-message {
    font-size: 12px;
  }

  .submit-button {
    width: 100%;
  }
}
