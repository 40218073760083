/* GLOBAL HOMEPAGE */
.image-container {
    position: relative;
}

.signin-button:hover {
    cursor: pointer;
    background-color: #07306D
}

.center > .background {
    position: absolute;
}

.footer-container {
  position: fixed;
  left: -12px;
  bottom: 12px;
  width: 100%;
  z-index: 1;
  background-color: transparent;
}

@media screen and (min-width: 481px){
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100vw;
}
    .image-container > .background-image {
        position: absolute;
        height: 979px;
        width: 1326px;
        right: -591px;
        top: -465px;
        z-index: 0;
      }
      
      .image-container >.boy-image {
        position: absolute;
        height: 662.91px;
        width: 200px;
        right: 380px;
        top: -275px;
        z-index: 1;
      }
      
      .image-container >.girl-image {
        position: absolute;
        height: 539.61px;
        width: 200px;
        right: -565px;
        top: -340px;
        z-index: 1;
      }
    
      .home > h1 {
        margin-top: 144px;
        width: 563px;
        height: 144px;
        font-family: 'Roboto Condensed';
        font-size: 64px;
        line-height: 72px;
        text-align: center;
        color: #252930;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
        z-index: 1;
      }
      
      .home > h4 {
        margin-top: 12px;
        margin-bottom: 48px;
        width: 563px;
        height: 32px;
        font-family: 'Roboto Condensed';
        font-size: 28px;
        line-height: 32px;
      
        text-align: center;
      
        color: #D7840B;
      
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
        z-index: 1;
      }

      .signin-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
      
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
      
        width: 259px;
        height: 64px;
        left: 590px;
        top: 492px;
      
        color: #F7F9FC;
        background: #0B5DD7;
      
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
        border-radius: 8px;
        border-width: 0px;
        z-index: 1;
      }
}

@media screen and (max-width: 480px){
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
}
    .image-container > .background-image {
        position: absolute;
        height: 459px;
        width: 622px;
        right: -200px;
        top: -295px;
        z-index: 0;
      }
      
      .image-container >.boy-image {
        position: absolute;
        height: 662.91px;
        width: 200px;
        right: 380px;
        top: -210px;
        z-index: 1;
      }
      
      .image-container >.girl-image {
        position: absolute;
        height: 539.61px;
        width: 200px;
        right: -565px;
        top: -275px;
        z-index: 1;
      }
    
      .home > h1 {
        margin-top: 108px; /* 144px * 0.75 */
        width: 422px; /* 563px * 0.75 */
        height: 108px; /* 144px * 0.75 */
        font-family: 'Roboto Condensed';
        font-size: 48px; /* 64px * 0.75 */
        line-height: 54px; /* 72px * 0.75 */
        text-align: center;
        color: #252930;
        text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16); /* Adjusted the shadow size */
        z-index: 1;
      }
      
      .home > h4 {
        margin-top: 9px; /* 12px * 0.75 */
        margin-bottom: 36px; /* 48px * 0.75 */
        width: 422px; /* 563px * 0.75 */
        height: 24px; /* 32px * 0.75 */
        font-family: 'Roboto Condensed';
        font-size: 21px; /* 28px * 0.75 */
        line-height: 24px; /* 32px * 0.75 */
        text-align: center;
        color: #D7840B;
        text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16); /* Adjusted the shadow size */
        z-index: 1;
      }
      
      .signin-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 24px;
      
        width: 194.25px;
        height: 48px;
        left: 442.5px;
        top: 369px;
      
        color: #F7F9FC;
        background: #0B5DD7;
      
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
        border-radius: 8px;
        border-width: 0px;
        z-index: 1;
      }
}