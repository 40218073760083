.portfolio {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100dvw;
}

.portfolio-left {
  height: auto;
  padding: 24px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.portfolio-right {
  /* overflow-x: hidden; */
  /* width: 100%; */
  padding: 24px;
  /* width: 900px; */
}

.tab-heading {
  width: 100%;
  height: 56px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;
  display: flex;
  align-items: flex-end;
  color: #757d87;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 24px 0px;
}

.account-information {
  margin-left: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

/* 
.portfolio-right::-webkit-scrollbar {
  background: transparent;
}

.portfolio-right:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.portfolio-right::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.h4-portfolio {
  margin: 0px 0px 8px 0px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #000000;
}

#percentage-portfolio {
  margin-left: 0;
}

.account-value {
  margin: 0px 0px 4px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #757d87;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.account-value-summary {
  margin: 44px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.account-value-summary > p {
  border-bottom: 2px solid #ced5de;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px;
}

.account-value-summary > p > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.portfolio-left > .quick-price > .secondary-price {
  margin: 0px 4px 0px 0px;
}

.chart-switch-container {
  position: fixed;
  bottom: 32px;
  right: -20px;
  z-index: 10;
}

.quick-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
}

.secondary-price {
  margin-top: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #757d87;
  margin-left: 0px;
  margin-right: 8px;
}

.account-value-summary.darkModeContainer > p > span {
  color: #f0f0f0;
}

.tab-heading.darkModeContainer {
  color: #c6cfda;
}

.h4-portfolio.darkModeContainer {
  color: #f0f0f0;
}

.reset-button {
  color: var(--mustard);
  /* color: #0b5dd7; */
  cursor: pointer;
}

.reset-button.darkModeContainer {
  color: var(--mustard-dark);
  /* color: #387adb; */
}

.placeholder-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 12px;
  color: #757d87;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 75dvh;
  margin: 0px;
}

.detail-button {
  color: var(--mustard);
  /* color: #acb4be; */
  /* color: #0b5dd7; */
  /* color: #f0f0f0; */
  /* color: #387adb; */
  /* color: #000000; */
  /* color: #757d87; */
  cursor: pointer;
}

.detail-button.darkModeContainer {
  color: var(--mustard-dark);
  /* color: #387adb; */
}

.reset-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media screen and (min-width: 481px) and (max-width: 850px) {
  .portfolio {
    flex-direction: column;
    overflow-y: scroll;
  }

  .portfolio-left {
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px;
  }

  .portfolio-right {
    width: calc(100% - 78px);
    height: auto;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 851px) {
  .portfolio-left {
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px;
  }

  .portfolio-right {
    width: calc(100% - 408px - 78px);
    height: auto;
    box-sizing: border-box;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 480px) {
  .portfolio-left {
    width: 100%;
  }

  .portfolio-right {
    width: 100%;
    height: auto;
    overflow-y: scroll;
  }
}
