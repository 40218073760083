.communities {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100dvw;
}

.communities-left{
  height: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.communities-right {
  padding: 24px;
}

.tab-heading {
  width: 100%;
  height: 56px;  
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 56px;  
  display: flex;
  align-items: flex-end;  
  color: #757D87;  
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px 24px 0px;
}

.tab-heading.darkModeContainer {
  color: #C6CFDA;
}

.communities-button-selected {
  border: none;
  background: transparent;
  margin: 0px;
  padding: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #0B5DD7;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.communities-button-selected.darkModeContainer {
  color: #387ADB;
}

.communities-button {
  margin: 0px;
  padding: 0px;
  background: transparent;
  border: none;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #ACB4BE;

  flex: none;
  order: 0;
  flex-grow: 0;
}
  
.communities-button:hover {
  cursor: pointer;
}

.communities-button-container > button {
  margin: 0px 24px 0px 24px;
}

.submit-button{
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #F7F9FC;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 38px;
  background: #0B5DD7;
  border-radius: 8px;
  width: 100%;
  }

.submit-button.darkModeContainer {
  color: #F0F0F0;
  background: #387ADB;
}

.submit-button:hover{
  cursor: pointer;
  background-color: #07306D
}

.submit-button:disabled{
  background:  #F9F9F9;
  color: rgb(0, 0, 0, 0.1);
  cursor: default;
}

.list-container > p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 265px;
  height: 34px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ACB4BE;
}

.communities-row-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 20px;
  flex-shrink: 0;
  margin-bottom: 10px;
}

.communities-row-element:hover {
  cursor: pointer;
}

.communities-row-subelement-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}

.subelement-change {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #757D87;
}

.subelement-ticker {
  margin-bottom: 4px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

.subelement-ticker.darkModeContainer {
  color: #F0F0F0;
}

.communities-row-subelement-right {
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  margin-right: 16px;
}

/* .communities-right::-webkit-scrollbar {
  background: transparent;
}

.communities-right:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.communities-right::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.communities-br-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  background: #FFFFFF;
}

.communities-br-content-container.darkModeContainer {
  background: #272835;
}

.code-search {
  height: 38px;
  margin-bottom: 12px;
  margin-top: 17px;
  border: 1px solid #000000;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
}

.communities-br-content-button-container{
  overflow: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;

  /* margin-left: 32px; */
  margin-bottom: 12px;
  /* margin-right: 32px; */
}

/* .communities-br-content-button-container::-webkit-scrollbar {
  background: transparent;
}

.communities-br-content-button-container:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.communities-br-content-button-container::-webkit-scrollbar-corner {
  background-color: transparent;
} */

.communities-br-content-button {
  color: #000000;
}

.communities-br-content-button.darkModeContainer {
  color: #F0F0F0;
}

.communities-br-content-button:hover{
  cursor: pointer;
}

.subelement-shortname span{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
    
  color: #757D87;
}

.subelement-price {
  margin-bottom: 4px;
  font-family: 'Inter';
  font-style: normal;    
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-align: right;

  color: #000000;
}

.subelement-price.darkModeContainer {
  color: #F0F0F0;
}

#separator {
  font-size: smaller;
}

.communities-alert{
    margin: 0;
}

.placeholder-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 265px;
  height: 34px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ACB4BE;
}

.list-container {
  border: 1px solid #757D87;
  border-radius: 20px;
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-container::-webkit-scrollbar {
  display: none;
}

.communities-row-element:last-child {
  margin-bottom: 0px;
}

.communities-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px 0px 12px 0px;
}

.communities-br-content-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 8px 16px;


  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  background-color: transparent;
  border: 0px;
  border-bottom: 4px solid #CED5DE;
  white-space: nowrap;
  }

  .add-icon:hover {
    cursor: pointer;
  }

@media screen and (min-width: 481px) and (max-width: 850px) {
  .communities {
    flex-direction: column;
    overflow-y: scroll;
  }

  .communities-left{
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px
  }

  .communities-right {
    width: calc(100% - 78px);
    box-sizing: border-box;
  }

  .list-container {
    height: 400px;
  }

  .communities-row-element {
    height: 72px;
  }

  .communities-br-content-container {
    margin-top: 24px;
    padding: 24px;
  }

}

@media screen and (min-width: 851px){
  .communities-left{
    width: 408px;
    box-sizing: border-box;
  }

  .tab-heading {
    margin-top: 24px
  }

  .communities-right {
    width: calc(100% - 408px - 78px);
    box-sizing: border-box;
    overflow-y: scroll;
    height: auto;
  }

  .list-container {
    max-height: 400px;
  }

  .communities-row-element {
    height: 72px;
  }

  .communities-br-content-container {
    margin-top: 24px;
    padding: 24px;
  }
}

@media screen and (max-width: 480px){
  .communities-left {
    width: 100%;
  }

  .communities-right {
    width: 100%;
    overflow-y: scroll;
    height: auto;
  }

  .list-container {
    max-height: 340px;
  }

  .communities-row-element {
    height: 60px;
  }

  .communities-br-content-container {
    margin-top: 12px;
    padding: 24px 12px;
  }
}