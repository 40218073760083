.settings {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 3;
    background-color: #FFFFFF;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    transform: translate(-50%, 0);
  }
  
  .settings > h4 {
    font-family: 'Roboto Condensed';
    font-size: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 229px;
    height: 32px;
    line-height: 32px;
  }
  
  .settings-container {
    display: flex;
    flex-direction: column;
  }
  
  .settings-container-subelement {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }
  
  .settings-subheader {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }
  
  .settings-container-option {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    margin: 0px 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .settings-text {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    font-weight: 400;

    display: flex;
    align-items: center;
  }

  .settings.darkModeContainer {
    background-color: #272835;
    color: #F0F0F0;
    /* transition: background-color 0.3s ease; */
  }

  .settings-container-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .settings-container-content-left {
    display: flex;
    flex-direction: column;
  }

  .settings-container-content-left span {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
  }

  .default-text {
    font-family: 'Roboto';
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    color: #9E9E9E;
    width: 40px;
    /* margin-bottom: 4px; */
  }

  .default-text.darkModeContainer {
    color: #C6CFDA;
  }

  .edit-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .cancel-icon {
    margin-left: 6px;
  }

  #settings-star {
    margin-left: 0;
  }
  #settings-star img:hover {
    cursor: pointer;
  }

  #fixed-height {
    height: 93.22px;
  }

  @media screen and (min-width: 481px){
    .settings {
      padding: 32px;
      /* width: 487px; */
      /* height: 506px; */
      top: 76px;
      left: 50%;
    }

    .settings h4 {
      margin: 12px 0px 32px 0px;
      /* margin-top: 64px; */
      /* margin-bottom: 48.5px; */
    }

    .settings-container {
      width: 336px;
    }

    .edit-buttons {
      width: 80px;
    }
  }

  @media screen and (max-width: 480px){
    .settings {
      padding: 32px;
      /* width: 340px;
      height: 420px; */
      top: 63px;
      left: 50%;
    }

    .settings h4 {
      margin: 0px 0px 24px 0px;
      /* margin-top: 32px;
      margin-bottom: 32px; */
    }

    .settings-container {
      width: 269px;
    }
  }