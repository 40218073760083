/* SUMMARY */

.summary{
    display: flex;
    flex-direction:row;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 0px;
    /* margin-right: 32px; */
    overflow: auto;
  }

.summary.darkModeContainer {
  color: #F0F0F0;
}

/* .summary::-webkit-scrollbar {
  background: transparent;
}

.summary:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.summary::-webkit-scrollbar-corner {
  background-color: transparent;
} */
  
  .summary-table{
    /* margin: 0 12px 0px 12px; */
    width: 48%;
    max-width: 248px;
    /* min-width: 110px; */
  }

  .summary-table tbody tr {
    /* min-height: 80px; */
  }
  
  .summary-table > tbody > tr > td{
      border-bottom: 1px solid #CED5DE;
      padding-left: 4px;
      padding-right: 4px;
      height: 25px;
  }
  
  .table-middle{
    text-align: center;
  }
  .table-right{
    text-align: right;
    padding-right:4px;
  }
 
  @media screen and (min-width: 481px){
    .summary {
      width: 100%;
      justify-content: center;
    }
    .summary-table {
      margin: 0px 12px
    }

    .td-summary-header {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
      }
      .td-summary-data{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        text-align: right;
      }
  }

  @media screen and (max-width: 480px){
    .summary {
      min-height: 278px;
    }

    .td-summary-header {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
      }
      .td-summary-data{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        text-align: right;
      }
  }