/* EDIT PROFILE */
.communities-create {
  background-color: #ffffff;
  overflow: auto;
  padding-bottom: 24px;
}

.communities-create.darkModeContainer {
  background-color: #272835;
  color: #f0f0f0;
}

.communities-create > form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-button {
  background: #0b5dd7;
  color: #f7f9fc;
}

.signup-button.darkModeContainer {
  background: #387adb;
  color: #f0f0f0;
}

.signup-button:hover {
  cursor: pointer;
  background-color: #07306d;
}

.signup-bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  /* margin: 12px 10.5px 0px 4.55px; */
  margin-top: 4px;
}

.main-post-item {
  border-radius: 10px;
}

.post-item {
  /* margin-left: 12px; */
  /* margin-right: 12px; */
  /* margin-bottom: 12px; */
  /* margin-top: 12px; */
  /* padding: 12px; */
  border-radius: 10px;
  margin-top: 12px;
}

.user-and-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.post-username {
  font-family: "Roboto Condensed";
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
}

.post-username:hover {
  color: #0b5dd7;
  cursor: pointer;
}

.post-username.darkModeContainer:hover {
  color: #387adb;
}

.pin-more {
  display: flex;
  flex-direction: row;
}

.more-ref {
  position: relative;
}

.more-dropdown {
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  /* border: 1px solid gray; */
  position: absolute;
  cursor: pointer;

  z-index: 10;
  width: 80px;
  right: 0px;
  /* top: 60px; */

  /* background-color: #f1f1f1; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 4px;
}

.dropdown-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 100%; */
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f9f9f9;
  /* background-color: #272835; */
  z-index: 10;
  padding: 8px 16px;
  /* color: #F0F0F0; */
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.dropdown-row:hover {
  background-color: #f1f1f1;
}

.dropdown-row.darkModeContainer {
  background-color: #272835;
  color: #f0f0f0;
}

.more-dropdown.darkModeContainer {
  background-color: #272835;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-row:hover.darkModeContainer {
  background-color: #232339;
}

.post-date {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #757d87;
  margin: 0px;
}

.post-title {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 8px 0px 0px 0px;
}

.post-description {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;
}

.icon-group {
  display: flex;
  flex-direction: row;
}

.icon-and-number {
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  margin-top: 12px;
  width: 24px;
  height: 32px;
}

.icon-and-number p {
  font-size: 12px;
}

.word-count {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: #757d87;
  margin-top: -4.55px;
}

/* .communities-create::-webkit-scrollbar {
  background: transparent;
}

.communities-create:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.communities-create::-webkit-scrollbar-corner {
  background-color: transparent;
}
.communities-create::-webkit-scrollbar-track-piece:start {
  background-color: transparent;
  margin-top: 12px; 
}

.communities-create::-webkit-scrollbar-track-piece:end {
  background-color: transparent;
  margin-bottom: 12px; 
} */

.communities-create::-webkit-scrollbar {
  display: none;
}

@media screen and (min-width: 481px) {
  .communities-create {
    position: fixed;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    z-index: 3;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 76px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow-y: scroll;
    max-height: calc(100dvh - 189px);
  }

  .signup-button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* line-height: 22px; */

    width: 100px;
    height: 44px;
    /* left: 298px;
    top: 630px; */

    border-radius: 8px;
    border: none;
  }

  .signup-bottom {
    width: 328px;
    /* margin-top: 15.5px; */
  }
  .main-post-item {
    width: 328px;
  }

  .post-item {
    width: 328px;
  }

  .word-count {
    width: 328px;
  }
}

@media screen and (max-width: 480px) {
  .communities-create {
    position: fixed;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 3;
    box-shadow: 7px 9px 12px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    top: 63px;
    left: 50%;
    transform: translate(-50%, 0);
    overflow-y: scroll;
    max-height: calc(100dvh - 189px);
  }

  .signup-button {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    /* line-height: 22px; */

    width: 80px;
    height: 44px;
    /* left: 298px;
        top: 630px; */

    border-radius: 8px;
    border: none;
  }

  .signup-bottom {
    /* justify-content: space-between; */
    /* margin-top: 12px; */

    width: 260px;
  }

  .main-post-item {
    width: 260px;
  }
  .post-item {
    width: 260px;
  }
  .word-count {
    width: 260px;
  }
}
