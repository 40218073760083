.portfolio-left h5 {
  font-size: 24px;
}

.h4-portfolio {
  margin: 0px 0px 8px 0px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: #000000;
}

.h4-portfolio.darkModeContainer {
  color: #f0f0f0;
}

.account-value {
  margin: 0px 0px 4px 0px;
  /* width: 313px;
  height: 16px; */

  /* Labels/Default */

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 16px; */
  /* identical to box height, or 114% */

  /* Greyscale/Dark Grey */

  color: #757d87;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.quick-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 32px; */
  /* color: #068B03; */

  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
}

.portfolio-left > .quick-price > .secondary-price {
  margin: 0px 4px 0px 0px;
}

.secondary-price {
  margin-top: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #757d87;
  margin-left: 0px;
  margin-right: 8px;
}

/* .portfolio-left::-webkit-scrollbar {
  background: transparent;
}

.portfolio-left:hover::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border: 4px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

.portfolio-left::-webkit-scrollbar-corner {
  background-color: transparent;
}

.portfolio-left::-webkit-scrollbar-track-piece:start {
  background-color: transparent;
  margin-left: 32px; 
}

.portfolio-left::-webkit-scrollbar-track-piece:end {
  background-color: transparent;
  margin-right: 0px; 
} */

.account-value-summary {
  margin: 44px 0px 0px 0px;
  display: flex;
  flex-direction: column;
}

.account-value-summary > p {
  border-bottom: 2px solid #ced5de;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* min-width: 220px; */
  margin: 8px 0px;
}

.account-value-summary > p > span {
  /* width: 111px;
height: 24px; */

  /* Labels/Large */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* line-height: 24px; */
  /* identical to box height, or 133% */

  color: #000000;

  /* Inside auto layout */

  /* flex: none;
order: 0;
flex-grow: 0; */
}

.chart-switch-container {
  position: fixed;
  bottom: 32px;
  right: -20px;
  z-index: 10;
}

.account-value-summary.darkModeContainer > p > span {
  color: #f0f0f0;
}

.tab-heading.darkModeContainer {
  color: #c6cfda;
}

.reset-button {
  color: var(--mustard);
  cursor: pointer;
}

.reset-button.darkModeContainer {
  color: var(--mustard-dark);
}

.portfolio-left {
  /* height: calc(100dvh - 63px); */
  /* height: 264.5px; */
  /* min-width: 360px; */
  width: 100%;
  /* padding: 24px 12px; */
  margin-top: 12px;
}

.account-value-summary p {
  width: 100%;
}

.account-information {
  margin-left: 0px;
}

@media screen and (min-width: 481px) {
  .portfolio-left {
    max-width: 360px;
  }
}

@media screen and (max-width: 480px) {
}
